<template>
  <div class="barChart" ref="Echart"></div>
</template>

<script>
import * as echarts from "echarts";
export default {
  props: {
    list: {
      type: Array,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      echart: null,
    };
  },
  computed: {
    lang() {
      return this.$i18n.locale;
    },
  },
  watch: {
    list: {
      handler(list) {
        const xData = [];
        const yData = [];
        if (list && list.length) {
          list.forEach((item, index) => {
            if (item.date) {
              const open = Number(
                list[index > 0 ? index - 1 : index].derivedUSD
              );
              const close = Number(item.derivedUSD);
              const lowest = open > close ? close : open;
              const highest = open > close ? open : close;
              xData.push(item.date);
              // [开盘值, 收盘值, 最低值, 最高值]
              yData.push({
                value: [open, close, lowest, highest],
              });
            }
          });
        }
        this.xData = xData;
        this.yData = yData;
        this.setOptions();
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart();
    });
    window.addEventListener(
      "resize",
      this.debounce(() => {
        this.echart && this.echart.resize();
      })
    );
  },
  beforeDestroy() {
    if (this.echart) {
      this.echart.dispose();
      this.echart = null;
    }
  },
  methods: {
    debounce(fn, interval) {
      var timer;
      var gapTime = interval || 150;
      return function () {
        clearTimeout(timer);
        var context = this;
        var args = arguments;
        timer = setTimeout(function () {
          fn.call(context, args);
        }, gapTime);
      };
    },
    initChart() {
      this.echart = echarts.init(this.$refs.Echart);
      this.setOptions();
    },
    setOptions() {
      let option = {
        grid: {
          top: "5%",
          left: "10%",
          bottom: "15%",
          right: "15%",
        },
        tooltip: {
          show: true,
          trigger: "axis",
          axisPointer: {
            type: "line",
            axis: "x",
            label: {
              show: false,
            },
          },
          formatter: (params) => {
            const point = params[0];
            // x轴数据的索引值
            let date = point.axisValue;
            let value = point.value[2];
            this.$emit("setValue", { date, value });
            return "";
          },
        },
        dataZoom: [
          {
            type: "inside",
          },
        ],
        xAxis: {
          axisLine: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: true,
            align: "center",
            textStyle: {
              fontSize: 11,
              color: "#7A6EAA", //X轴文字颜色
            },
            formatter: (val) => {
              let day = this.$dateFormat(val, "mm/dd hh:MM TT");
              return day;
            },
          },
          data: this.xData,
        },
        yAxis: {
          max: "dataMax",
          axisLine: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          position: "right",
          axisTick: {
            show: false,
          },
          scale: true,
          axisLabel: {
            show: true,
            textStyle: {
              fontSize: 11,
              color: "#7A6EAA", //X轴文字颜色
            },
            formatter: (num) => {
              if (num > 1000000) {
                return Number(num / 1000000).toFixed(2) + "M";
              }
              if (num > 1000) {
                return Number(num / 1000).toFixed(2) + "K";
              }
              return Number(num).toFixed(2);
            }, //右侧Y轴文字显示
          },
        },

        series: [
          {
            type: "candlestick",
            data: this.yData,
            itemStyle: {
              color: "#3cd0ab",
              color0: "#eb489d",
              borderColor: "#3cd0ab",
              borderColor0: "#eb489d",
            },
          },
        ],
      };
      if (!this.echart) return;
      this.echart.setOption(option);
      // var _this = this;
      // // this.echart.getZr().off();
      // this.echart.getZr().on("click", function (params) {
      //   let pointInPixel = [params.offsetX, params.offsetY];
      //   let pointInGrid = _this.echart.convertFromPixel(
      //     { seriesIndex: 0 },
      //     pointInPixel
      //   );
      //   // x轴数据的索引值
      //   let value = pointInGrid[1];
      //   let date = option.xAxis.data[pointInGrid[0]];
      //   _this.$emit("setValue", { date, value });
      // });
    },
  },
};
</script>
<style lang="less" scoped>
.barChart {
  width: 100%;
  height: 100%;
}
</style>
