<template>
  <div class="container" :class="{ tw: lang === 'tw', ios: $isIos }">
    <app-header />
    <index-banner :info="info" :lang="lang" />
    <!-- <index-destroy :info="info" :lang="lang" /> -->
    <index-reward :info="info" :lang="lang" />
    <index-trade :nowData="nowData" :predayData="predayData" :lang="lang" />
    <index-liquidity
      :nowData="nowData"
      :predayData="predayData"
      :pre2dayData="pre2dayData"
      :pre7dayData="pre7dayData"
      :nowLiquidity="nowLiquidity"
      :lang="lang"
    />
    <index-charts
      :liquidityList="liquidityList"
      :klineList="klineList"
      :lang="lang"
    />
    <index-add-bar :lang="lang" />
    <app-footer />
  </div>
</template>
<script>
import AppHeader from "components/AppHeader";
import AppFooter from "components/AppFooter";
import IndexBanner from "./IndexBanner";
// import IndexDestroy from "./IndexDestroy";
import IndexReward from "./IndexReward";
import IndexTrade from "./IndexTrade";
import IndexLiquidity from "./IndexLiquidity";
import IndexCharts from "./IndexCharts";
import IndexAddBar from "./IndexAddBar";
import request from "@/request";
export default {
  components: {
    AppHeader,
    AppFooter,
    IndexBanner,
    // IndexDestroy,
    IndexReward,
    IndexTrade,
    IndexLiquidity,
    IndexCharts,
    IndexAddBar,
  },
  data() {
    return {
      now: 0,
      info: {},
      blockInfos: {},
      liquidityList: [],
      klineList: [],
    };
  },
  computed: {
    preday() {
      return this.now - 1 * 24 * 60 * 60;
    },
    pre2day() {
      return this.now - 2 * 24 * 60 * 60;
    },
    pre7day() {
      return this.now - 7 * 24 * 60 * 60;
    },
    nowData() {
      let arr = this.blockInfos[`now`];
      if (arr && arr.length) {
        return arr[0] || {};
      }
      return {};
    },
    predayData() {
      let arr = this.blockInfos[`preday`];
      if (arr && arr.length) {
        return arr[0] || {};
      }
      return {};
    },
    pre2dayData() {
      let arr = this.blockInfos[`pre2day`];
      if (arr && arr.length) {
        return arr[0] || {};
      }
      return {};
    },
    pre7dayData() {
      let arr = this.blockInfos[`pre7day`];
      if (arr && arr.length) {
        return arr[0] || {};
      }
      return {};
    },
    nowLiquidity() {
      return this.liquidityList[this.liquidityList.length - 1];
    },
    lang() {
      return this.$i18n.locale;
    },
  },
  watch: {},
  created() {
    this.getNowTime();
    this.getInfo();
    this.getBlocks();
    this.getLiquidity();
    this.getKlineTimes();
  },
  methods: {
    getNowTime() {
      this.now = Date.parse(new Date()) / 1000;
    },
    getInfo() {
      request({
        url: `${process.env.VUE_APP_BASE_URL}/mobile/deflation/public/info`,
        method: "get",
      }).then((res) => {
        this.info = res.data;
      });
    },
    getKlineTimes() {
      // 获取最近7天的整点时间戳
      const date = new Date();
      const dateStr = `${date.getFullYear()}/${date.getMonth() + 1}/${
        date.getDate() + 1
      } ${date.getHours()}:00:00`;
      const time = Date.parse(new Date(dateStr)) / 1000;
      const timeArray = [];
      for (let i = 0; i < 7 * 24; i++) {
        timeArray.push(time - i * 60 * 60);
      }
      this.getBlocks(timeArray);
    },
    async getBlocks(list) {
      const isKine = list && list.length;
      // t${this.now}:blocks(first: 1, orderBy: timestamp, orderDirection: desc, where: { timestamp_lt: ${this.now} }) { number },
      let query = `query blocks { t${
        this.preday
      }:blocks(first: 1, orderBy: timestamp, orderDirection: desc, where: { timestamp_gt: ${
        this.preday
      }, timestamp_lt: ${this.preday + 600} }) { number },t${
        this.pre2day
      }:blocks(first: 1, orderBy: timestamp, orderDirection: desc, where: { timestamp_gt: ${
        this.pre2day
      }, timestamp_lt: ${this.pre2day + 600} }) { number },t${
        this.pre7day
      }:blocks(first: 1, orderBy: timestamp, orderDirection: desc, where: { timestamp_gt: ${
        this.pre7day
      }, timestamp_lt: ${this.pre7day + 600} }) { number } }`;

      if (isKine) {
        query = `query blocks { `;
        list.forEach((item, index) => {
          query += `t${item}:blocks(first: 1, orderBy: timestamp, orderDirection: desc, where: { timestamp_gt: ${item}, timestamp_lt: ${
            item + 600
          } }) { number }`;
          if (index < list.length - 1) {
            query += ", ";
          }
        });
        query += " }";
      }
      const res = await request({
        url: `${process.env.VUE_APP_PANCAKE_URL}/subgraphs/name/pancakeswap/blocks`,
        method: "post",
        data: { query },
      });
      // 取出区块
      const obj = res.data;
      const result = {};

      for (const key in obj) {
        if (Object.hasOwnProperty.call(obj, key)) {
          const item = obj[key];
          if (item && item.length && item[0] && item[0].number) {
            // if (key === `t${this.now}`) {
            //   result["now"] = item[0].number;
            // }
            if (isKine) {
              result[key] = item[0].number;
            } else {
              if (key === `t${this.preday}`) {
                result["preday"] = item[0].number;
              }
              if (key === `t${this.pre2day}`) {
                result["pre2day"] = item[0].number;
              }
              if (key === `t${this.pre7day}`) {
                result["pre7day"] = item[0].number;
              }
            }
          }
        }
      }
      this.getBlockInfo(result, isKine);
    },
    getBlockInfo(result, isKine) {
      if (!result || !Object.keys(result).length) {
        return;
      }
      let query = `query tokens {  now:tokens(first: 1, skip: 0, orderBy: tradeVolumeUSD, orderDirection: desc, where: { id: "0xe9c7a827a4ba133b338b844c19241c864e95d75f" }) {id, name, symbol, decimals, tradeVolume, tradeVolumeUSD, totalTransactions,totalLiquidity, derivedUSD } `;
      let arr = Object.keys(result);
      for (let i = 0; i < arr.length; i++) {
        const key = arr[i];
        const block = result[key];
        query += ` ${key}:tokens(first: 1, skip: 0, block: {number: ${block}}, orderBy: tradeVolumeUSD, orderDirection: desc, where: { id: "0xe9c7a827a4ba133b338b844c19241c864e95d75f" }) {id, name, symbol, decimals, tradeVolume, tradeVolumeUSD, totalTransactions,totalLiquidity, derivedUSD } `;
        if (i < arr.length - 1) {
          query += ", ";
        }
      }
      query += " }";
      let url = "http://pancakeswap1.fs.video:7777/query-tokens/cache";
      if (isKine) {
        url = "http://pancakeswap1.fs.video:7777/query-tokens";
      }
      request({
        // url: `https://bsc.streamingfast.io/subgraphs/name/pancakeswap/exchange-v2`,
        url,
        method: "post",
        data: { query },
      }).then((res) => {
        if (isKine) {
          let obj = res.data.data;
          let arr = [];
          for (const key in obj) {
            if (Object.hasOwnProperty.call(obj, key)) {
              const item = obj[key];
              if (key.includes("t")) {
                arr.push({
                  date: Number(key.replace(/t/g, "")),
                  derivedUSD: item[0].derivedUSD,
                });
              }
            }
          }
          arr.sort((a, b) => {
            return a.date - b.date;
          });
          this.klineList = arr;
          return;
        }
        this.blockInfos = res.data.data;
      });
    },
    getLiquidity() {
      request({
        // url: `https://bsc.streamingfast.io/subgraphs/name/pancakeswap/exchange-v2`,
        url: `http://pancakeswap1.fs.video:7777/query-token-day-datas`,
        method: "post",
        data: {
          query:
            "\n      query tokenDayDatas($startTime: Int!, $skip: Int!, $address: Bytes!) {\n        tokenDayDatas(\n          first: 1000\n          skip: $skip\n          where: { token: $address, date_gt: $startTime }\n          orderBy: date\n          orderDirection: asc\n        ) { date, dailyVolumeToken, dailyVolumeBNB, dailyVolumeUSD, dailyTxns, totalLiquidityToken, totalLiquidityBNB, totalLiquidityUSD, priceUSD }\n      }\n    ",
          variables: {
            startTime: 1619136000,
            skip: 0,
            address: "0xe9c7a827a4ba133b338b844c19241c864e95d75f",
          },
        },
      }).then((res) => {
        this.liquidityList = res.data.data.tokenDayDatas;
      });
    },
  },
};
</script>
<style lang="less" scoped></style>
