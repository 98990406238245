<template>
  <div class="trade flex" :class="{ tw: lang === 'tw' }">
    <img class="logo" src="~images/logo_small@2x.png" alt="" />
    <div class="info flex-item">
      <div class="title">FileSystemVideo<small>（FSV）</small></div>
      <div class="price">
        <template v-if="nowData.derivedUSD">
          ${{ nowData.derivedUSD | numFilter(2)
          }}<span class="rate" :class="rate >= 0 ? 'up' : 'down'"
            >{{ rate | abs }}%</span
          >
        </template>
      </div>
      <div class="buttons flex">
        <a
          class="add-btn"
          href="https://pancakeswap.finance/add/0xe9c7a827a4ba133b338b844c19241c864e95d75f"
          ref="nofollow"
          target="_blank"
          >{{ $t("Add Liquidity") }}</a
        >
        <a
          class="trade-btn"
          ref="nofollow"
          target="_blank"
          href="https://pancakeswap.finance/swap?outputCurrency=0xe9c7a827a4ba133b338b844c19241c864e95d75f"
          >{{ $t("Trade") }}</a
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    lang: String,
    nowData: {
      type: Object,
      default() {
        return {};
      },
    },
    predayData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    rate() {
      const now = this.nowData.derivedUSD || "";
      const pre = this.predayData.derivedUSD || "";
      let rate = (((now - pre) / pre) * 100).toFixed(2) || "";
      return rate;
    },
  },
  watch: {},
  created() {},
  methods: {},
  components: {},
};
</script>
<style lang="less" scoped>
.trade {
  margin-top: 2px;
  height: 375px;
  padding: 84px 21px 0;
  background: url(~images/bg2@2x.png) no-repeat 50% 50%;
  background-size: cover;
  overflow: hidden;
  &.tw {
    margin-top: -38px;
  }
}
.logo {
  display: block;
  width: 100px;
  height: 100px;
}
.info {
  padding-top: 18px;
}
.title {
  font-size: 48px;
  font-family: Kanit;
  font-weight: 600;
  color: #260073;
  font-family: "Kanit-SemiBold";
  line-height: 52px;
  small {
    font-size: 28px;
    font-family: "Kanit-Regular";
    font-weight: normal;
    color: #7a6eaa;
  }
}
.price {
  margin-top: 16px;
  line-height: 48px;
  height: 48px;
  font-size: 44px;
  font-family: "Kanit-SemiBold";
  font-weight: 600;
  color: #260073;
}
.rate {
  font-size: 30px;
  font-family: "Kanit-SemiBold";
  font-weight: 600;
  margin-left: 20px;
}
.buttons {
  margin-top: 24px;
}
.add-btn {
  display: block;
  text-align: center;
  line-height: 80px;
  min-width: 290px;
  height: 86px;
  background: #ffffff;
  border: 3px solid #34ccd8;
  border-radius: 30px;
  font-size: 30px;
  font-family: "Kanit-SemiBold";
  font-weight: 600;
  color: #32cbd8;
}
.trade-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  min-width: 168px;
  height: 86px;
  background: #30cad7;
  border: 3px solid #34ccd8;
  box-shadow: 0px 1px 0px 0px #188297;
  border-radius: 30px;
  border-radius: 30px;
  font-size: 30px;
  font-family: "Kanit-SemiBold";
  font-weight: 600;
  color: #ffffff;
}
</style>
