<template>
  <div class="charts">
    <div class="tabs">
      <div
        class="tab-item"
        @click="changeType('volume')"
        :class="{ active: type === 'volume' }"
      >
        {{ $t("Volume") }}
      </div>
      <div
        class="tab-item"
        @click="changeType('liquidity')"
        :class="{ active: type === 'liquidity' }"
      >
        {{ $t("Liquidity") }}
      </div>
      <div
        class="tab-item"
        @click="changeType('price')"
        :class="{ active: type === 'price' }"
      >
        {{ $t("Price") }}
      </div>
    </div>
    <div class="chart-tips">
      <div class="title">
        <template v-if="value">${{ value | numFilter(2) }}</template>
      </div>
      <div class="sub-title">
        <template v-if="date">
          <template v-if="lang === 'tw'">
            {{
              $dateFormat(
                date,
                type === "price" ? "yyyy年mm月dd日 HH:MM" : "yyyy年mm月dd日"
              )
            }}
          </template>
          <template v-else>
            {{
              $dateFormat(
                date,
                type === "price" ? "UTC:mmm d, yyyy h:MM TT" : "UTC:mmm d, yyyy"
              )
            }}
          </template>
          <span v-show="type === 'price'">(UTC)</span>
        </template>
      </div>
    </div>
    <div class="chart-box">
      <bar-chart
        v-if="type === 'volume'"
        :list="liquidityList"
        @setValue="setValue"
      />
      <line-chart
        v-if="type === 'liquidity'"
        :list="liquidityList"
        @setValue="setValue"
      />
      <kline-chart
        v-if="type === 'price'"
        :list="klineList"
        @setValue="setValue"
      />
    </div>
  </div>
</template>
<script>
import BarChart from "components/barChart";
import klineChart from "components/klineChart";
import lineChart from "components/lineChart";
export default {
  props: {
    lang: String,
    liquidityList: {
      type: Array,
      default() {
        return [];
      },
    },
    klineList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  components: {
    BarChart,
    klineChart,
    lineChart,
  },
  data() {
    return {
      type: "volume",
      value: "",
      date: "",
    };
  },
  watch: {
    liquidityList: {
      handler(val) {
        this.initData();
      },
      immediate: true,
    },
  },
  methods: {
    setValue(value) {
      this.value = value.value || "";
      this.date = value.date || "";
    },
    changeType(type) {
      if (this.type === type) return;
      this.type = type;
      this.initData();
    },
    initData() {
      const type = this.type;
      if (type === "volume" && this.liquidityList.length) {
        let item = this.liquidityList[this.liquidityList.length - 1];
        this.value = item.dailyVolumeUSD;
        this.date = item.date;
      }
      if (type === "liquidity" && this.liquidityList.length) {
        let item = this.liquidityList[this.liquidityList.length - 1];
        this.value = item.totalLiquidityUSD;
        this.date = item.date;
      }
      if (type === "price" && this.klineList.length) {
        let item = this.klineList[this.klineList.length - 1];
        this.value = item.derivedUSD;
        this.date = item.date;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.chart-tips {
  padding: 44px 48px 0;
  background: #fff;
  height: 88px;
  box-sizing: content-box;
  .title {
    color: #280d5f;
    font-family: "Kanit-SemiBold";
    line-height: 52px;
    font-size: 48px;
  }
  .sub-title {
    margin-top: 4px;
    color: #7645d9;
    font-family: "Kanit-Medium";
    font-size: 24px;
    line-height: 32px;
  }
}
.charts {
  display: flex;
  flex-direction: column;
  margin: 30px 40px 0;
  height: 716px;
  background: #eeeaf4;
  border: 1px solid #d1d6e7;
  box-shadow: 0px 5px 0px 0px #d1d6e7;
  border-radius: 40px;
  overflow: hidden;
}
.tabs {
  display: flex;
  height: 100px;
  line-height: 100px;

  .tab-item {
    font-size: 28px;
    font-family: "Kanit-Regular";
    font-weight: 400;
    color: #280d5f;
    flex: 1;
    min-width: 0;
    text-align: center;
    border-radius: 40px 40px 0 0;
    &.active {
      background: #fff;
    }
  }
}
.chart-box {
  flex: 1;
  min-height: 0;
  background: #fff;
  overflow: hidden;
}
</style>
