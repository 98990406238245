<template>
  <div class="barChart" ref="Echart" />
</template>

<script>
import * as echarts from "echarts";
export default {
  props: {
    list: {
      type: Array,
      default() {
        return {};
      },
    },
    liquidityList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  computed: {
    lang() {
      return this.$i18n.locale;
    },
  },
  watch: {
    list: {
      handler(list) {
        const xData = [];
        const yData = [];
        if (list && list.length) {
          list.forEach((item) => {
            if (item.date) {
              xData.push(item.date);
              yData.push(item.totalLiquidityUSD);
            }
          });
        }
        this.xData = xData;
        this.yData = yData;
        this.setOptions();
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart();
    });
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$refs.Echart);
      this.setOptions();
    },
    setOptions() {
      let option = {
        grid: {
          top: "5%",
          left: "10%",
          right: "20%",
          bottom: "15%", //也可设置left和right设置距离来控制图表的大小
        },
        tooltip: {
          show: true,
          trigger: "axis",
          axisPointer: {
            type: "line",
            axis: "x",
            label: {
              show: false,
            },
            lineStyle: {
              type: "solid",
              color: "#7646d6",
            },
          },
          formatter: (params) => {
            const point = params[0];
            // x轴数据的索引值
            let date = point.axisValue;
            let value = point.value;
            this.$emit("setValue", { date, value });
            return "";
          },
        },
        dataZoom: [
          {
            type: "inside",
          },
        ],
        xAxis: {
          type: "category",
          data: this.xData,
          axisLine: {
            show: false, //隐藏X轴轴线
            lineStyle: {
              color: "#7646d6",
            },
          },
          axisTick: {
            show: false, //隐藏X轴刻度
          },
          axisLabel: {
            show: true,
            align: "center",
            textStyle: {
              fontSize: 11,
              color: "#7A6EAA", //X轴文字颜色
            },
            formatter: (val) => {
              // const day = new Date(val * 1000).getDate();
              // // xData.push(`${day}${this.lang === "tw" ? "日" : "D"}`);
              // return `${day}${this.lang === "tw" ? "日" : "D"}`;
              return this.$dateFormat(val, "mm-dd");
            },
          },
        },
        yAxis: {
          type: "value",
          max: "dataMax",
          // type: "value",
          position: "right",
          // rigth: 20,
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: true,
            textStyle: {
              fontSize: 11,
              color: "#7A6EAA", //X轴文字颜色
            },
            formatter: (num) => {
              if (num > 1000000) {
                return "$" + Number(num / 1000000).toFixed(2) + "M";
              }
              if (num > 1000) {
                return "$" + Number(num / 1000).toFixed(2) + "K";
              }
              return "$" + Number(num).toFixed(2);
            }, //右侧Y轴文字显示
          },
        },

        series: [
          {
            type: "line",
            smooth: true,
            barWidth: 2,
            symbol:
              "image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAMAAAC6V+0/AAAAS1BMVEUAAAD///////////////////////////////////////////////////////92Rtb///9+UNj08PzTxPK4n+qOZt16S9ePZ955Stf8YC0KAAAAD3RSTlMAVKoG+t7YiVvkA/OUkwdXD6zCAAAAj0lEQVQY023Q2w6EIAxF0XIVcWY8KCD//6UDhGhj2I8raSilnhLS6M1KoehOWIysGOQXsBbfsdkRU84pHk37LIAz7L1wAhBEaq2231VdFbk6Gx4MB+BIAnFnRUCSAS6OCTCkgcyxAJq2F4aG9jV+AWb60HSl2fK/2TfZQUphByH/Bevj50ceKSeN1kY6Ra0/a3cS4JpMZ50AAAAASUVORK5CYII=",
            symbolSize: 6,
            showSymbol: false,
            itemStyle: {
              normal: {
                barBorderRadius: [2, 2, 0, 0],
                color: "#7646d6",
                areaStyle: {
                  opacity: 0.6,
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: "#b399e7",
                    },
                    {
                      offset: 1,
                      color: "rgba(255,255,255,0)",
                    },
                  ]),
                },
              },
            },
            data: this.yData,
          },
        ],
      };
      if (!this.chart) return;
      this.chart.setOption(option);
      // var _this = this;
      // // this.chart.getZr().off();
      // this.chart.getZr().on("click", function (params) {
      //   var pointInPixel = [params.offsetX, params.offsetY];
      //   if (_this.chart.containPixel("grid", pointInPixel)) {
      //     var xIndex = _this.chart.convertFromPixel({ seriesIndex: 0 }, [
      //       params.offsetX,
      //       params.offsetY,
      //     ])[0];
      //     var value = option.series[0].data[xIndex];
      //     var date = option.xAxis.data[xIndex];
      //     _this.$emit("setValue", { date, value });
      //   }
      // });
    },
  },
};
</script>
<style lang="less">
.barChart {
  width: 100%;
  height: 100%;
}
</style>
