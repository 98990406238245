<template>
  <div class="banner">
    <h1 class="title">
      {{
        $t("Next generation video and entertainment blockchain powered by you.")
      }}
    </h1>
    <div class="sub-title" v-if="lang === 'tw'">
      每銷毀
      <strong>1</strong> 枚FSV，<br />您將獲得
      <strong>{{ space | sizeFormat }}</strong> 的存儲空間。
    </div>
    <div class="sub-title" v-else>
      For every <strong>1</strong> FSV destroyed, <br />
      you will get <strong>{{ space | sizeFormat }}</strong> of storage space.
    </div>

    <!-- <div class="join">{{ $t("Will you join them?") }}</div> -->
  </div>
</template>
<script>
export default {
  props: {
    lang: String,
    info: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    space() {
      if (this.info && this.info.fsv_price) {
        return (1 / this.info.fsv_price) * 1024 * 1024 * 1024;
      }
      return "";
    },
  },
};
</script>
<style lang="less" scoped>
.tw {
  .title {
    font-size: 60px;
    font-weight: bold;
  }
  .sub-title {
    font-size: 28px;
    font-weight: 400;
  }
}
.ios:not(.tw) {
  .title {
    letter-spacing: -4px;
  }
}
.banner {
  position: relative;
  height: 700px;
  background: url(~images/bg_top@2x.png) no-repeat 50% 0;
  background-size: 100% 100%;
  padding: 0 40px;
  overflow: hidden;
  text-align: center;
}
.title {
  margin-top: 140px;
  line-height: 70px;
  font-size: 52px;
  font-family: "Kanit-Bold";
  color: #ffffff;
  text-shadow: 0px 5px 0px rgba(31, 71, 153, 0.3);
}
.sub-title {
  margin-top: 30px;
  line-height: 36px;
  font-size: 32px;
  font-family: "Kanit-Regular";
  color: #ffffff;
  strong {
    font-family: "Kanit-Bold";
    color: #ffff00;
  }
}
.join {
  position: absolute;
  left: 0;
  bottom: 8px;
  width: 100%;
  line-height: 60px;
  font-size: 56px;
  font-family: "Kanit-SemiBold";
  font-weight: 700;
  color: #592db3;
  // line-height: 44px;
  text-align: center;
  overflow: visible;
}
</style>
