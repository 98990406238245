<template>
  <footer class="footer" :class="{ tw: lang === 'tw' }">
    <h2 class="title">{{ $t("ABOUT") }}</h2>
    <a href="https://www.fs.video/#fsv" class="nav-item">{{
      $t("About FSV")
    }}</a>
    <a href="https://www.fs.video/#advantage" class="nav-item">{{
      $t("Advantage")
    }}</a>
    <a href="https://www.fs.video/#history" class="nav-item">{{
      $t("History")
    }}</a>
    <a id="download" href="https://www.fs.video/#route_map" class="nav-item">{{
      $t("Route Map")
    }}</a>
    <h2 class="title">{{ $t("Download") }}</h2>
    <div
      class="fold"
      :class="{ active: toggleList[index] }"
      v-for="(item, index) in downloadList"
      :key="item.plat"
    >
      <div class="fold-title" @click="toggleLink(index)">
        {{ item.plat }} <i class="icon"></i>
      </div>
      <div class="fold-details">
        <span class="nav-item">{{ $t("Version") }}: {{ item.version }}</span>
        <a
          :href="link.url"
          class="nav-item"
          v-for="link in item.links"
          :key="link.area"
          download
          >{{ link.area }}</a
        >
      </div>
    </div>
    <!-- https://dsw3rxqm9wq1x.cloudfront.net/book/FSV%E7%99%BD%E7%9A%AE%E4%B9%A6.pdf -->
    <a
      href="https://dsw3rxqm9wq1x.cloudfront.net/book/FSV-White-Paper.pdf"
      class="nav-item"
      download
      >{{ $t("White Paper") }}</a
    >
    <h2 class="title">{{ $t("Help") }}</h2>
    <a href="https://www.fs.video/help.html#help-new" class="nav-item">{{
      $t("Newbie Help")
    }}</a>
    <a href="https://www.fs.video/help.html#help-dpos" class="nav-item">{{
      $t("DPoS Tutorial")
    }}</a>
    <h2 class="title">{{ $t("Contact Us") }}</h2>
    <a href="mailto:filesystemvideo@gmail.com" class="nav-item"
      >filesystemvideo@gmail.com</a
    >
    <!-- <h2 class="title">{{ $t("Double excavation") }}</h2>
    <a class="nav-item" href="https://www.fs.video/excavation.html">{{
      $t("Chia Double excavation(windows)")
    }}</a> -->
    <h2 class="title">{{ $t("Buy") }}</h2>
    <a
      class="nav-item"
      ref="nofollow"
      target="_blank"
      href="https://pancakeswap.finance/swap?outputCurrency=0xE9c7a827a4bA133b338b844C19241c864E95d75f"
      >{{ $t("Pancakeswap") }}</a
    >

    <div class="share bt-1px">
      <a
        class="share-icon"
        ref="nofollow"
        target="_blank"
        href="https://github.com/FileSystemVideo/"
        ><img src="~images/github@2x.png" alt=""
      /></a>
      <a
        class="share-icon"
        href="https://t.me/FsvVideo"
        ref="nofollow"
        target="_blank"
        ><img src="~images/telegraph@2x.png" alt=""
      /></a>
      <a
        class="share-icon"
        href="https://t.me/FSV1111"
        ref="nofollow"
        target="_blank"
        ><img src="~images/telegraph@2x.png" alt=""
      /></a>
      <a
        class="share-icon"
        href="https://twitter.com/FSV_fsvideo"
        ref="nofollow"
        target="_blank"
        ><img src="~images/Twitter@2x.png" alt=""
      /></a>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      appVersion: "22.01.17",
      winVersion: "22.2.10",
      linuxVersion: "22.02.09",
      toggleList: [],
    };
  },
  computed: {
    lang() {
      return this.$i18n.locale;
    },
    downloadList() {
      return [
        {
          plat: `Windows ${this.$t("Client")}`,
          version: this.winVersion,
          links: [
            {
              area: this.$t("Asia"),
              url: `https://dsw3rxqm9wq1x.cloudfront.net/pc/FSV-Setup-${this.winVersion}.exe`,
            },
            {
              area: this.$t("America"),
              url: `https://dsw3rxqm9wq1x.cloudfront.net/pc/FSV-Setup-${this.winVersion}.exe`,
            },
            {
              area: this.$t("Europe"),
              url: `https://d58qqi0wa6uyo.cloudfront.net/pc/FSV-Setup-${this.winVersion}.exe`,
            },
          ],
        },
        {
          plat: `Linux ${this.$t("Client")}`,
          version: this.linuxVersion,
          links: [
            {
              area: this.$t("Asia"),
              url: `https://dsw3rxqm9wq1x.cloudfront.net/linux/fsv-${this.linuxVersion}.zip`,
            },
            {
              area: this.$t("America"),
              url: `https://dsw3rxqm9wq1x.cloudfront.net/linux/fsv-${this.linuxVersion}.zip`,
            },
            {
              area: this.$t("Europe"),
              url: `https://d58qqi0wa6uyo.cloudfront.net/linux/fsv-${this.linuxVersion}.zip`,
            },
          ],
        },
        {
          plat: "App",
          version: this.appVersion,
          links: [
            {
              area: this.$t("Asia"),
              url: `https://dsw3rxqm9wq1x.cloudfront.net/app/bql_release_v${this.appVersion}.apk`,
            },
            {
              area: this.$t("America"),
              url: `https://dsw3rxqm9wq1x.cloudfront.net/app/bql_release_v${this.appVersion}.apk`,
            },
            {
              area: this.$t("Europe"),
              url: `https://d58qqi0wa6uyo.cloudfront.net/app/bql_release_v${this.appVersion}.apk`,
            },
          ],
        },
      ];
    },
  },
  created() {},
  methods: {
    toggleLink(index) {
      const flag = !this.toggleList[index];
      this.$set(this.toggleList, index, flag);
    },
  },
  components: {},
};
</script>
<style lang="less" scoped>
.tw {
  .title {
    font-size: 36px;
    font-weight: bold;
  }
  .fold-title,
  .nav-item {
    font-size: 28px;
    font-weight: normal;
  }
  .fold-details {
    .nav-item {
      font-size: 24px;
      font-weight: 400;
      line-height: 60px;
    }
  }
}
.footer {
  margin-top: 72px;
  background: #27262c;
  color: #cfd6e6;
  overflow: hidden;
  padding: 40px;
}
.title {
  margin-bottom: 15px;
  font-size: 42px;
  font-family: "Kanit-SemiBold";
  color: #6666ff;
  line-height: 44px;
  &:not(:first-child) {
    margin-top: 45px;
  }
}
.nav-item {
  display: block;
  font-size: 32px;
  font-family: "Kanit-Regular";
  color: #cfd6e6;
  line-height: 2;
}
.fold {
  .icon {
    display: block;
    width: 24px;
    height: 24px;
    background: url(~images/plus@2x.png) no-repeat 50% 50%;
    background-size: 100% 100%;
  }
  .fold-details {
    display: none;
  }
  &.active {
    .icon {
      background-image: url(~images/minus@2x.png);
    }
    .fold-details {
      display: block;
    }
  }
}
.fold-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 32px;
  font-family: "Kanit-Regular";
  color: #cfd6e6;
  line-height: 2;
}
.fold-details {
  .nav-item {
    font-size: 28px;
    font-family: "Kanit-Thin";
    color: #cfd6e5;
    font-weight: 100;
    line-height: 60px;
    text-indent: 1em;
  }
}
.share {
  margin-top: 30px;
  display: flex;
  padding: 41px 0 0;
  .share-icon {
    margin-right: 30px;
    img {
      display: block;
      width: 44px;
      height: 44px;
    }
  }
}
</style>
