<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>
<script>
// 引入动画库
import "animate.css";
export default {
  name: "app",
  mounted() {
    if (this.$isApp) {
      // 在app中隐藏头部
      document.querySelector("body").classList.add("in-app");
    }
    if (this.$isWeChat) {
      document.querySelector("body").classList.add("in-wechat");
    }
    // if (this.$isIos) {
    // 修复ios下输入后弹出框按钮无法点击的bug
    // window.addEventListener("focusout", function () {
    //   setTimeout(() => {
    //     window.scrollTo(
    //       0,
    //       document.documentElement.scrollTop || document.body.scrollTop
    //     );
    //   }, 100);
    // });
    // 禁止ios10 以上页面缩放
    // document.documentElement.addEventListener(
    //   "touchstart",
    //   function (event) {
    //     if (event.touches.length > 1) {
    //       event.preventDefault();
    //     }
    //   },
    //   false
    // );
    // let lastTouchEnd = 0;
    // document.addEventListener(
    //   "touchend",
    //   function (event) {
    //     let now = Date.now();
    //     if (now - lastTouchEnd <= 300) {
    //       event.preventDefault();
    //     }
    //     lastTouchEnd = now;
    //   },
    //   false
    // );
    // // 阻止双指放大
    // document.addEventListener("gesturestart", function (event) {
    //   event.preventDefault();
    // });
    // }
  },
  components: {},
};
</script>
<style lang="less">
@import "./assets/common.less";
</style>
