<template>
  <div class="reward">
    <h1 class="title">{{ $t("Entrepreneur's motivation") }}</h1>
    <template v-if="lang === 'tw'">
      <div class="p tw">
        每 {{ this.space | sizeFormat }} 區塊鏈存儲空間 14400 區塊間隔的<br />獎勵為
        <strong style="color: #ff7f00">{{ reward }}</strong
        >FSV。
      </div>
      <div class="p tw">每天都有獎勵平均分配給所有創業者。</div>
    </template>
    <template v-else>
      <div class="p">
        Every <strong>{{ this.space | sizeFormat }}</strong> of blockchain
        storage space
        <br />
        The block interval of 14400 will <br />
        reward
        <strong style="color: #ff7f00">{{ reward }}</strong> FSV.
      </div>
      <div class="p">
        Rewards are distributed equally to all <br />
        entrepreneurs every day.
      </div>
    </template>
  </div>
</template>
<script>
export default {
  props: {
    lang: String,
    info: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    space() {
      if (this.info && this.info.fsv_price) {
        return (1 / this.info.fsv_price) * 1024 * 1024 * 1024;
      }
      return "";
    },
    reward() {
      if (this.space) {
        return (
          (this.info.fsv_reward / (1024 * 1024 * 1024)) *
          this.space
        ).toFixed(6);
      }
      return "";
    },
  },
};
</script>
<style lang="less" scoped>
.ios:not(.tw) {
  .title {
    letter-spacing: -2px;
  }
}
.tw .reward .title {
  font-size: 60px;
}
.reward {
  margin-top: -137px;
  height: 400px;
  padding: 135px 45px 0;
  background: url(~images/bg1@2x.png) no-repeat 50% 50%;
  background-size: cover;
  text-align: center;
  overflow: visible;
  .title {
    text-align: center;
    font-size: 50px;
    font-family: "Kanit-Bold";
    font-weight: bold;
    color: #260073;
    line-height: 64px;
    white-space: nowrap;
  }
  .p {
    margin-top: 32px;
    font-size: 30px;
    font-family: "Kanit-Medium";
    font-weight: 500;
    color: #330099;
    line-height: 34px;
    &.tw {
      font-size: 26px;
      font-weight: 400;
      font-family: sans-serif;
      white-space: nowrap;
    }
  }
}
</style>
