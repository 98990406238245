import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueI18n from "vue-i18n";
import dateFormLatLight from "dateformat-light";
import fileSize from "filesize";
Vue.use(VueI18n);
Vue.config.productionTip = false;
import tw from "./locales/tw.json";
import en from "./locales/en.json";
const obg = {};
const i18n = new VueI18n({
  locale: "en",
  fallbackLocale: "en",
  messages: {
    tw: {
      ...tw,
    },
    en: {
      ...en,
    },
  },
  missing: (locale, key) => {
    obg[key] = key;
  },
});

// 文件大小格式化
Vue.filter("sizeFormat", (size) => {
  if (size && Number(size)) {
    return fileSize(Number(size), { round: 4, base: 2, standard: "jedec" });
  }
  return "";
});

window.saveLang = () => {
  let text = "";
  for (const key in obg) {
    if (Object.hasOwnProperty.call(obg, key)) {
      text += `"${key}": "${key}", \n`;
    }
  }
};
const lang = localStorage.getItem("lang") || "en";
const setLang = (lang) => {
  i18n.locale = lang;
  localStorage.setItem("lang", lang);
};
setLang(lang);
Vue.prototype.$setLang = setLang;
Vue.prototype.$getLang = () => {
  return i18n.locale;
};

// 判断设备类型
const UA = navigator.userAgent;
// eslint-disable-next-line no-prototype-builtins
Vue.prototype.$isApp = window.hasOwnProperty("flutter_inappwebview");
Vue.prototype.$isIos = /(iPhone|iPad|iPod|iOS)/i.test(UA);
Vue.prototype.$isAndroid = /(Android)/i.test(UA);
Vue.prototype.$isWeChat = UA.toLowerCase().match("micromessenger");

// 文件大小格式化
Vue.filter("numFilter", (num, dot) => {
  if (Number(num)) {
    if (num > 1000000) {
      return Number(num / 1000000).toFixed(dot) + "M";
    }
    if (num > 1000) {
      return Number(num / 1000).toFixed(dot) + "K";
    }

    return Number(num).toFixed(dot);
  }
  return num;
});

Vue.filter("abs", (num) => {
  return String(num).replace(/-/, "");
});

Vue.prototype.$dateFormat = (time, type = "yyyy-mm-dd HH:MM:ss") => {
  if (!time || !Number(time)) {
    return "";
  }
  const date = time ? new Date(time * 1000) : new Date();
  return dateFormLatLight(date, type);
};

new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
