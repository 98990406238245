// import Vue from "vue";
import axios from "axios";

// create an axios instance
console.log(process.env.VUE_APP_BASE_URL);
const service = axios.create({
  // baseURL: process.env.VUE_APP_BASE_URL,
});
// request interceptor
service.interceptors.request.use(
  (config) => {
    if (
      config.method === "post" &&
      Object.prototype.toString.call(config.data) !== "[object FormData]"
    ) {
      config.headers["content-type"] = "application/json";
      config.data = config.data || {};
    }
    return config;
  },
  (error) => {
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    const res = response.data;
    if (
      Object.prototype.hasOwnProperty.call(res, "status") &&
      res.status !== 1
    ) {
      return Promise.reject(new Error(res.info || "Error"));
    } else {
      return res;
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default service;
