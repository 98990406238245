<template>
  <div class="barChart" ref="Echart" />
</template>

<script>
import * as echarts from "echarts";
export default {
  props: {
    list: {
      type: Array,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  computed: {
    lang() {
      return this.$i18n.locale;
    },
  },
  watch: {
    list: {
      handler(list) {
        const xData = [];
        const yData = [];
        if (list && list.length) {
          list.forEach((item) => {
            if (item.date) {
              xData.push(item.date);
              yData.push(item.dailyVolumeUSD);
            }
          });
        }
        this.xData = xData;
        this.yData = yData;
        this.setOptions();
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart();
    });
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$refs.Echart);
      this.setOptions();
    },
    setOptions() {
      let option = {
        grid: {
          top: "5%",
          left: "10%",
          right: "20%",
          bottom: "15%", //也可设置left和right设置距离来控制图表的大小
        },
        dataZoom: [
          {
            type: "inside",
          },
        ],
        tooltip: {
          show: true,
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            axis: "x",
            label: {
              show: false,
            },
            shadowStyle: {
              color: "rgba(150,150,150,0.2)",
            },
          },
          formatter: (params) => {
            const point = params[0];
            // x轴数据的索引值
            let date = point.axisValue;
            let value = point.value;
            this.$emit("setValue", { date, value });
            return "";
          },
        },
        toolbox: {
          show: true,
        },
        xAxis: {
          type: "category",
          data: this.xData,
          axisLine: {
            show: false, //隐藏X轴轴线
          },
          axisTick: {
            show: false, //隐藏X轴刻度
          },
          axisLabel: {
            show: true,
            align: "center",
            textStyle: {
              fontSize: 11,
              color: "#7A6EAA", //X轴文字颜色
            },
            formatter: (val) => {
              return this.$dateFormat(val, "mm-dd");
            },
          },
        },
        yAxis: {
          type: "value",
          max: "dataMax",
          nameTextStyle: {
            color: "#7a6eaa",
          },
          position: "right",
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: true,
            textStyle: {
              fontSize: 11,
              color: "#7A6EAA", //X轴文字颜色
            },
            formatter: (num) => {
              if (num > 1000000) {
                return "$" + Number(num / 1000000).toFixed(2) + "M";
              }
              if (num > 1000) {
                return "$" + Number(num / 1000).toFixed(2) + "K";
              }
              return "$" + Number(num).toFixed(2);
            }, //右侧Y轴文字显示
          },
        },
        series: [
          {
            type: "bar",
            barWidth: 2,
            itemStyle: {
              normal: {
                barBorderRadius: [2, 2, 0, 0],
                color: "#31C7D1",
              },
            },
            data: this.yData,
            tooltip: {},
          },
        ],
      };
      if (!this.chart) return;
      this.chart.setOption(option);
      // const _this = this;
      // // this.chart.getZr().off();
      // this.chart.getZr().on("click", function (params) {
      //   let pointInPixel = [params.offsetX, params.offsetY];
      //   let pointInGrid = _this.chart.convertFromPixel(
      //     { seriesIndex: 0 },
      //     pointInPixel
      //   );
      //   // x轴数据的索引值
      //   let value = pointInGrid[1];
      //   let date = option.xAxis.data[pointInGrid[0]];
      //   console.log(value);
      //   _this.$emit("setValue", { date, value });
      // });
    },
  },
};
</script>
<style lang="less">
.barChart {
  width: 100%;
  height: 100%;
}
</style>
