<template>
  <div class="liquidity">
    <div class="row">
      <div class="label">{{ $t("LIQUIDITY") }}</div>
      <template v-if="nowData.tradeVolumeUSD">
        <div class="number">
          ${{ nowLiquidity.totalLiquidityUSD | numFilter(2) }}
        </div>
        <div class="rate" :class="liquidityRate >= 0 ? 'up' : 'down'">
          {{ liquidityRate | abs }}%
        </div>
      </template>
      <div v-else class="h2"></div>
    </div>

    <div class="row">
      <div class="label">{{ $t("VOLUME 24H") }}</div>
      <template v-if="nowData.tradeVolumeUSD">
        <div class="number">
          ${{
            (nowData.tradeVolumeUSD - predayData.tradeVolumeUSD) | numFilter(2)
          }}
        </div>
        <div class="rate" :class="volumeRate >= 0 ? 'up' : 'down'">
          {{ volumeRate | abs }}%
        </div>
      </template>
      <div v-else class="h2"></div>
    </div>
    <div class="row">
      <div class="label">{{ $t("VOLUME 7D") }}</div>
      <template v-if="nowData.tradeVolumeUSD">
        <div class="number">
          ${{
            (nowData.tradeVolumeUSD - pre7dayData.tradeVolumeUSD) | numFilter(2)
          }}
        </div>
      </template>
      <div v-else class="h1"></div>
    </div>
    <div class="row">
      <div class="label">{{ $t("TRANSACTIONS 24H") }}</div>
      <template v-if="nowData.tradeVolumeUSD">
        <div class="number">
          {{
            (nowData.totalTransactions - predayData.totalTransactions)
              | numFilter(0)
          }}
        </div>
      </template>
      <div v-else class="h1"></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    lang: String,
    nowData: {
      type: Object,
      default() {
        return {};
      },
    },
    predayData: {
      type: Object,
      default() {
        return {};
      },
    },
    pre2dayData: {
      type: Object,
      default() {
        return {};
      },
    },
    pre7dayData: {
      type: Object,
      default() {
        return {};
      },
    },
    nowLiquidity: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    liquidityRate() {
      const now = this.nowData.totalLiquidity * this.nowData.derivedUSD;
      const pre = this.predayData.totalLiquidity * this.predayData.derivedUSD;
      let rate = (((now - pre) / pre) * 100).toFixed(2) || "";
      return rate;
    },
    volumeRate() {
      const now = this.nowData.tradeVolumeUSD - this.predayData.tradeVolumeUSD;
      const pre =
        this.predayData.tradeVolumeUSD - this.pre2dayData.tradeVolumeUSD;
      let rate = (((now - pre) / pre) * 100).toFixed(2) || "";
      return rate;
    },
  },
  watch: {},
  created() {},
  methods: {},
  components: {},
};
</script>
<style lang="less" scoped>
.h2 {
  min-height: 98px;
}
.h1 {
  min-height: 46px;
}
.row {
  overflow: hidden;
  padding: 28px 50px 30px;
  background: linear-gradient(90deg, #ffffff, #f2f4ff);
  box-sizing: border-box;
  &:nth-child(2n) {
    background: linear-gradient(-90deg, #ffffff, #f2f4ff);
  }
  &:nth-child(1) {
    padding-top: 42px;
    height: 202px;
  }
  &:nth-child(2) {
    height: 183px;
  }
  &:nth-child(3) {
    height: 130px;
  }
  &:nth-child(4) {
    height: 145px;
  }
}
.liquidity {
  margin: -10px 40px 0;
  // height: 660px;
  background: #ffffff;
  border: 1px solid #d1d6e7;
  box-shadow: 0px 5px 0px 0px #d1d6e7;
  border-radius: 40px;
  overflow: hidden;
}
.label {
  line-height: 24px;
  font-size: 22px;
  font-family: "Kanit-SemiBold";
  color: #7645d9;
  text-transform: uppercase;
  // &:first-child {
  //   margin-top: 50px;
  // }
  // &:not(:first-child) {
  //   margin-top: 48px;
  // }
}
.number {
  margin-top: 12px;
  line-height: 46px;
  font-size: 44px;
  font-family: "Kanit-SemiBold";
  color: #260073;
  min-height: 46px;
}
.rate {
  margin-top: 16px;
  font-size: 28px;
  font-family: "Kanit-Regular";
  font-weight: 400;
  line-height: 30px;
  min-height: 30px;
}
</style>
