<template>
  <div>
    <header class="app-header flex-middle">
      <a href="/" class="logo"><img src="~images/logo@2x.png" alt="" /></a>
      <div class="flex-item"></div>
      <img
        @click="showLang = !showLang"
        class="global-icon"
        src="~images/global@2x.png"
        alt=""
      />
      <a href="#download"
        ><img class="icon" src="~images/app@2x.png" alt=""
      /></a>
      <div class="langs" v-show="showLang">
        <div @click="changeLang('en')" class="lang-item Kanit-Medium">
          English
        </div>
        <div @click="changeLang('tw')" class="lang-item bold f30">繁體中文</div>
      </div>
    </header>
    <div class="poster"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showLang: false,
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    document.addEventListener("click", this.clickFun);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.clickFun);
  },
  methods: {
    clickFun(e) {
      if (e.target.className !== "global-icon") {
        this.showLang = false;
      }
    },
    changeLang(lang) {
      this.$setLang(lang);
    },
  },
  components: {},
};
</script>
<style lang="less" scoped>
.app-header {
  position: fixed;
  top: 0;
  left: auto;
  z-index: 100;
  width: 100%;
  max-width: 750px;
  background: #fff;
  height: 88px;
  padding: 0 40px;
  border-bottom: 1px solid #e6e6e6;
  overflow: visible;
}
.poster {
  height: 88px;
}
.logo {
  height: 57px;
  img {
    display: block;
    height: 100%;
  }
}
.global-icon,
.icon {
  display: block;
  margin-left: 50px;
  height: 40px;
  width: 40px;
}
.langs {
  position: fixed;
  top: 88px;
  right: 40px;
  width: 220px;
  height: 210px;
  background: #ffffff;
  border: 1px solid #d1d6e7;
  border-radius: 8px;
  text-align: center;
  padding-top: 21px;
  .lang-item {
    font-size: 34px;
    color: #260073;
    line-height: 84px;
  }
  .f30 {
    font-size: 30px;
  }
}
</style>
