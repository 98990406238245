<template>
  <div>
    <section class="add-index-bar" v-show="showAddBar">
      <img src="~images/logo144.png" class="logo" alt="" />
      <div class="label">{{ $t("addFsv") }}</div>
      <button class="btn" type="button" @click="showModel">
        {{ $t("add") }}
      </button>
      <div class="close" @click="hideAddBar"></div>
    </section>
    <section class="model" v-if="modelVisible">
      <div class="close" @click="hideModel"></div>
      <div class="tip" v-if="brand === 'google'">
        * {{ $t("chrome differences") }}
      </div>
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="(item, index) in swiperList"
            :key="index"
          >
            <img class="img" :src="item.logo" alt="" />
            <div class="name" :key="item.name">{{ item.name }}</div>
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </section>
    <div class="model-shaow" v-if="modelVisible" @click="hideModel"></div>
  </div>
</template>
<script>
import Swiper from "swiper"; //  ES6 导入swiper js文件
import "swiper/dist/css/swiper.min.css"; // ES6 导入swiper css文件
export default {
  props: ["lang"],
  data() {
    return {
      showAddBar: true,
      // swiperList: [],
      modelVisible: false,
      isIos: false,
      swiper: null,
      showAddBtn: false,
    };
  },
  computed: {
    brand() {
      const UA = navigator.userAgent.toLowerCase();
      console.log(UA);

      const isInWechat = UA.match("micromessenger");
      const isUC = UA.includes("UCBrowser");
      const isIos = /(iphone|ipad|ipod|ios)/i.test(UA);
      const isHuawei = UA.includes("huawei");
      const isHonor = UA.match(/honor/i) == "honor";
      const isOppo = UA.includes("oppo") || UA.match("heytapbrowser");
      const isOppoR15 = UA.match(/pacm00/i) == "pacm00";
      const isVivo = UA.match(/vivo/i) == "vivo";
      const isXiaomi = UA.includes("xiaomi") || UA.match("miuibrowser");
      const isXiaomi2s = UA.match(/mix\s/i) == "mix ";
      const isRedmi = UA.match(/redmi/i) == "redmi";
      const isSamsung = UA.match(/sm-/i) == "sm-";
      if (isInWechat) {
        return "wechat";
      } else if (isUC) {
        return "uc";
      } else if (isIos) {
        return "ios";
      } else if (isHuawei || isHonor) {
        return "huawei";
      } else if (isOppo || isOppoR15) {
        return "oppo";
      } else if (isVivo) {
        return "vivo";
      } else if (isXiaomi || isRedmi || isXiaomi2s) {
        return "mi";
      } else if (isSamsung) {
        return "samsung";
      } else {
        return "google";
      }
    },
    swiperList() {
      let list = [];
      let lang = this.lang;
      let brand = this.brand;
      console.log("lang", lang);
      console.log("brand", brand);
      let tips = [
        this.$t("bottom popup"),
        this.$t("home screen"),
        this.$t("click to add"),
        this.$t("one-click"),
      ];
      if (brand === "uc") {
        tips = [
          this.$t("bottom popup"),
          this.$t("home screen"),
          this.$t("click to edit"),
          this.$t("select desktop"),
          this.$t("one-click"),
        ];
      }
      if (brand === "wechat") {
        tips = [this.$t("open in browser")];
      }
      for (let i = 0; i < tips.length; i++) {
        const tip = tips[i];
        try {
          const img = require(`images/A2HS/${brand}/${lang}/${i + 1}.png`);
          list.push({
            logo: img,
            name: tip,
          });
        } catch (error) {
          console.log(error);
        }
      }
      console.log(list);
      return list;
    },
  },
  mounted() {
    // let deferredPrompt;
    // const addBtn = this.$refs.addButton;
    // this.showAddBtn = false;
    // window.addEventListener("beforeinstallprompt", (e) => {
    //   // 防止 Chrome 67 及更早版本自动显示安装提示
    //   e.preventDefault();
    //   // 稍后再触发此事件
    //   deferredPrompt = e;
    //   this.showAddBtn = true;
    //   addBtn.addEventListener("click", (e) => {
    //     // 隐藏显示 A2HS 按钮的界面
    //     // 显示安装提示
    //     deferredPrompt.prompt();
    //     // 等待用户反馈
    //     deferredPrompt.userChoice.then((choiceResult) => {
    //       if (choiceResult.outcome === "accepted") {
    //         this.showAddBtn = false;
    //       } else {
    //         this.showAddBtn = true;
    //       }
    //       deferredPrompt = null;
    //     });
    //   });
    // });
    if (
      ("standalone" in window.navigator && window.navigator.standalone) ||
      window.matchMedia("(display-mode: standalone)").matches
    ) {
      this.showAddBar = false;
    }
  },
  methods: {
    hideAddBar() {
      this.showAddBar = false;
    },
    showModel() {
      this.modelVisible = true;
      if (this.swiperList && this.swiperList.length > 1) {
        this.$nextTick(() => {
          new Swiper(".swiper-container", {
            pagination: {
              el: ".swiper-pagination",
              clickable: true,
            },
          });
        });
      }
    },
    hideModel() {
      this.modelVisible = false;
    },
  },
  components: {},
};
</script>
<style lang="less" scoped>
.add-index-bar {
  position: fixed;
  left: 30px;
  top: auto;
  right: 30px;
  bottom: 24px;
  z-index: 1010;
  height: 110px;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 48px 0 20px;
}
.add-index-bar .logo {
  width: 70px;
  height: 70px;
  /* background: #13C09D; */
  border-radius: 16px;
}
.add-index-bar .label {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  min-width: 0;
  padding: 0 20px;
  font-size: 30px;
  font-weight: 500;
  color: #f9f9f9;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  line-height: 34px;
}
.add-index-bar .btn {
  width: 108px;
  height: 50px;
  background: #13c09d;
  border-radius: 12px;
  border: none;
  outline: none;
  font-size: 24px;
  font-weight: 500;
  color: #f9f9f9;
}
.add-index-bar .close {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  background: url(~images/close.png) no-repeat 20% 60%;
  background-size: 40%;
}
.model {
  position: fixed;
  top: 15%;
  left: 3%;
  z-index: 3000;
  width: 94%;
  border-radius: 12px;
  background: #fff;
  padding: 68px 48px 44px;
  /* box-sizing: border-box; */
}
.model .close {
  position: absolute;
  top: 48px;
  right: 40px;
  width: 42px;
  height: 42px;
  background: url(~images/close.png) no-repeat 50% 50%;
  background-size: 50% 50%;
  z-index: 10;
}
.swiper-wrapper {
  width: 100%;
  height: 100%;
}
.model .swiper-slide {
  padding: 40px 0 100px 0;
  text-align: center;
}
.model .name {
  color: #16221f;
  font-size: 32px;
  margin-top: 64px;
}
.model .img {
  display: block;
  width: 426px;
  height: 400px;
  margin: 0 auto;
}
.model .swiper-pagination-bullet-active {
  background: #13c09d;
}
.model .tip {
  font-size: 24px;
  color: #999;
}
.model-shaow {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;
  background: rgba(0, 0, 0, 0.5);
}
</style>
